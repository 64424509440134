import React from 'react';
import pocketMob from "../../theme/images/jewell/pocket-mob.png";



class Logo extends React.Component {

    constructor(props) {
        super(props);
    }



    render() {
        return (
            <>
                <div className='row'>
                    <div className='col-sm-6 col-xs-12'>

                        <div className='logo-corner'>
                            <img src={pocketMob} />
                        </div>
                    </div>
                    <div className='col-sm-6 col-xs-12'>
                        <div className='vertical-center'>
                            {/* E&#8209;com */}
                            <h2 className='main_head animated zoomIn'>Admin&nbsp;<span className='grey animated slideInUp'>FOR</span></h2>
                            <h5 className='sub_main_head animated flipInY pink'>Website </h5>
                            <h5 className='sub_main_head animated flipInX'>Tasks </h5>
                            <h5 className='sub_main_head animated flipInY'>Billing </h5>
                            <h5 className='sub_main_head animated flipInX'>Branding </h5>
                            <h5 className='sub_main_head animated flipInY'>Digitial Marketing </h5>
                            <h6 className='sub_main_content animated jackInTheBox'>Make Business Easy</h6>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}


export default Logo;
